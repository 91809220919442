import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import withStyles from "@material-ui/core/styles/withStyles";
import { withSnackbar } from "notistack";

import axios from "axios";

class PasswordChange extends Component {
  state = {
    busy: false,
    password_confirmation: "",
    password: ""
  };

  handleChange = (e, a) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value
    });
  };

  _onSubmit = e => {
    e.preventDefault();
    this.setState({ busy: true });
    let myUser = JSON.parse(localStorage.getItem("user"));

    axios
      .put("users/" + myUser.id, { password: this.state.password })
      .then(response => {
        this.setState({ busy: false });
        this.props.history.push("/logout");
      })
      .catch(err => {
        this.props.enqueueSnackbar("Coś poszło nie tak", { variant: "error" });
        this.setState({ busy: false });
      });
  };

  render() {
    let classes = this.props.classes;
    const { password, password_confirmation } = this.state;
    const isEnabled =
      password.length > 5 && password === password_confirmation ? true : false;

    return (
      <div className={classes.bg}>
        <div className={classes.layout}>
          <Paper className={classes.paper}>
            <form className={classes.form} onSubmit={this._onSubmit}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="email">Hasło</InputLabel>
                <Input
                  name="password"
                  type="password"
                  autoComplete="password"
                  autoFocus
                  onChange={this.handleChange}
                />
              </FormControl>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Powtórz hasło</InputLabel>
                <Input
                  name="password_confirmation"
                  type="password"
                  autoComplete="password_confirmation"
                  onChange={this.handleChange}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!isEnabled}
              >
                {this.state.busy ? (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                ) : (
                  "Zapisz"
                )}
              </Button>
            </form>
          </Paper>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  bg: {
    width: "100vw",
    height: "calc(100vh -70px)",
    position: "absolute"
  },
  layout: {
    width: "auto",
    display: "block", // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    width: "48px",
    height: "48px"
  },
  form: {
    width: "100%", // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withSnackbar(withStyles(styles)(PasswordChange));
