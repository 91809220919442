import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import moment from "moment";
import _ from 'lodash';

const OrderRow = props => {
  let { data, classes } = props;
  const time = moment(data.when).format("HH:mm");
  const paymentMaker = data.paymentMaker === "client" ? "Klient" : "Firma";

  let rootClasses = `${classes.root}`;

  if (data.status === "cancelled") {
    rootClasses = `${classes.root} ${classes.cancelled}`;
  }

  let mapsUrl = "https://www.google.com/maps/dir/?api=1";
  mapsUrl += "&waypoints=" + encodeURI(data.from.address);
  data.through.forEach(el => {
    if (el.address) mapsUrl += "|" + encodeURI(el.address);
  });
  mapsUrl += "&destination=" + encodeURI(data.to.address);
  mapsUrl += "&travelmode=driving";

  let toAddress = data.to && data.to.address ? data.to.address : "";
  let fromAddress = data.from && data.from.address ? data.from.address : "";

  let clientTels = data.client.tel.split(',');

  return (
    <div className={rootClasses}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography className={classes.time}>{time}</Typography>
          </div>
          <div className={classes.columnBig}>
            <p className={classes.tripElement}>
              <Icon className={classes.icon}>my_location</Icon>
              {fromAddress}
            </p>
            {data.through.map(el => {
              if (_.isEmpty(el)) return null;
              return (
                <p className={classes.tripElement} key={el.address}>
                  <Icon className={classes.icon}>trip_origin</Icon>
                  {el.address}
                </p>
              );
            })}
            <p className={classes.tripElement}>
              <Icon className={classes.iconDestination}>place</Icon> {toAddress}
            </p>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
          <div>
            <p>
              <span className={classes.label}>Firma</span>
              <br />
              {data.company.name}
            </p>
            <p>
              <span className={classes.label}>Zamawiający</span>
              <br />
              {data.orderer.name}{" "}
              {data.orderer.phone ? (
                <a href={"tel:" + data.orderer.phone.replace(/[^0-9+]+/g, "")}>
                  {data.orderer.phone}
                </a>
              ) : null}

            </p>
            <p>
              <span className={classes.label}>Liczb pasażerów</span>
              <br />
              {data.client.count}
            </p>
            <p>
              <span className={classes.label}>Pasażer</span>
              <br />
              {data.client.name}
            </p>
            <p>
              <span className={classes.label}>Telefon kontaktowy</span>
              <br />
              {clientTels.map((tel, index) => index > 0 ? <a href={"tel:" + tel.replace(/[^0-9+]+/g, "")}>
                , {tel}
              </a> : <a href={"tel:" + tel.replace(/[^0-9+]+/g, "")}>
                  {tel}
                </a>)}
            </p>
          </div>
          <div className={classes.padding}>
            <p>
              <span className={classes.label}>Nr. Lotu</span>
              <br />
              <a
                href={"https://www.google.pl/search?q=" + data.flightNumber}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data.flightNumber}
              </a>
            </p>
            <p>
              <span className={classes.label}>Płaci</span>
              <br />
              {paymentMaker}
            </p>
            <p>
              <span className={classes.label}>CC/MPK/Karta</span>
              <br />
              {data.costCenter}
            </p>
            <p>
              <span className={classes.label}>Kwota</span>
              <br />
              {data.paymentAmount} PLN
            </p>
          </div>
          <div className={classes.columnNotes}>
            <p className={classes.label}>uwagi</p>
            <p>{data.notes}</p>
            <p>{data.internalNotes}</p>
            <span className={classes.label}>Taxi</span>
            <br />
            {data.driverNumber}
          </div>
        </ExpansionPanelDetails>
        <ExpansionPanelActions>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            href={mapsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Nawigacja
          </Button>
        </ExpansionPanelActions>
      </ExpansionPanel>
      {data.status !== "cancelled" && !data.driverConfirmation ? (
        <div className={classes.relative}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={props.confirmOrder}
            disabled={data.busy}
          >
            Potwierdź
          </Button>
          {data.busy && (
            <CircularProgress
              size={24}
              color="secondary"
              className={classes.buttonProgress}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

const styles = theme => ({
  root: {
    width: "100%",
    marginBottom: "3px"
  },
  time: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: "500"
  },
  tripElement: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: "1.8rem",
    margin: 0
  },
  icon: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(16),
    width: "20px",
    height: "20px",
    color: "#2196f3"
  },
  iconDestination: {
    verticalAlign: "middle",
    fontSize: theme.typography.pxToRem(16),
    width: "20px",
    height: "20px",
    color: "#f50057"
  },
  columnBig: {
    padding: "0 2rem",
    flexGrow: "1"
  },
  padding: {
    padding: "0 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  listText: {
    color: theme.palette.text.secondary
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: "12px"
  },
  flexGrow: {
    flexGrow: "1"
  },
  statusIcon: {
    alignSelf: "center"
  },
  cancelled: {
    textDecoration: "line-through"
  },
  details: {
    [theme.breakpoints.down("sm")]: {
      display: "block"
    }
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withStyles(styles)(OrderRow);
