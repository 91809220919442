import React from "react";
import { withStyles } from "@material-ui/core/styles";

import moment from "moment";

const Pause = props => {
  let { data, classes } = props;

  return (
    <div className={classes.root}>
      <h3>Pauza</h3>
      <p>
        {moment(data.when).format("DD.MM HH:mm")} -{" "}
        {moment(data.end).format("DD.MM HH:mm")}
      </p>
    </div>
  );
};

const styles = theme => ({
  root: {
    width: "100%",
    marginBottom: "3px",
    padding: "2em",
    backgroundColor: "#000",
    color: "#fff"
  }
});

export default withStyles(styles)(Pause);
