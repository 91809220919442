import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import fileSaver from "file-saver";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Hidden from "@material-ui/core/Hidden";
import { withSnackbar } from "notistack";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import pl from "react-date-range/dist/locale/pl";
import moment from "moment";
import "moment/locale/pl";

import OrderRow from "./OrderRow";
import Pause from "./Pause";

moment.locale("pl");

class Orders extends Component {
  constructor(props) {
    super(props);

    let now = new Date();

    this.state = {
      orders: [],
      holidays: [],
      filter: "",
      pickerOpen: false,
      tempDateRange: {
        startDate: now,
        endDate: now,
        key: "selection"
      },
      dateRange: {
        startDate: moment(now).format("YYYY-MM-DD"),
        endDate: moment(now).format("YYYY-MM-DD")
      }
    };
  }

  componentDidMount() {
    this.refresh();
    this.interval = setInterval(this.refresh, 300000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  refresh = () => {
    let params = { ...this.state.dateRange };

    axios.get(`drivers/myholidays`, { params: params }).then(res => {
      const holidays = res.data.holidays;
      this.setState({ holidays });
    });

    axios
      .get(`orders`, { params: params })
      .then(res => {
        const orders = res.data.orders;
        this.setState({ orders: orders, filter: "" });
      })
      .catch(err => {
        this.props.enqueueSnackbar("Błąd! Spróbuj później", {
          variant: "error"
        });
      });
  };

  confirmOrderHandler = id => {
    let orders = this.state.orders;
    let orderIndex = _.findIndex(orders, order => order.id === id);

    if (orderIndex > -1) {
      orders[orderIndex].busy = true;
      this.setState({ orders });
    }

    axios
      .put("orders/" + id)
      .then(response => {
        let orders = this.state.orders;
        let orderIndex = _.findIndex(
          orders,
          order => order.id === response.data.order_id
        );

        if (orderIndex > -1) {
          orders[orderIndex].driverConfirmation = true;
          orders[orderIndex].busy = false;
          this.setState({ orders });
        }
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Coś poszło nie tak. Spróbuj jeszcze raz.";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  handleDateSelect = ranges => {
    let newDate = {
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
      key: "selection"
    };

    this.setState({ tempDateRange: newDate });
  };

  confirmDateRanges = () => {
    let newDate = {
      startDate: moment(this.state.tempDateRange.startDate).format(
        "YYYY-MM-DD"
      ),
      endDate: moment(this.state.tempDateRange.endDate).format("YYYY-MM-DD")
    };

    this.setState({ dateRange: newDate, orders: [] }, () => this.refresh());
    this.togglePicker();
  };

  togglePicker = () => {
    this.setState({ pickerOpen: !this.state.pickerOpen });
  };

  handleFilterChange = (e, a) => {
    this.setState({ filter: e.target.value });
  };

  changeDay = number => {
    let date = this.state.dateRange.startDate;
    date = moment(date)
      .add(number, "day")
      .format("YYYY-MM-DD");

    let newDateRange = {
      startDate: date,
      endDate: date
    };

    this.setState({ dateRange: newDateRange, orders: [] }, () =>
      this.refresh()
    );
  };

  getCSV = () => {
    let params = {
      startDate: this.state.dateRange.startDate,
      endDate: this.state.dateRange.endDate
    };

    axios
      .get("orders/xlsx", {
        params: params,
        responseType: "text",
        headers: { "Content-Type": "application/octet-stream" }
      })
      .then(res => {
        var byteCharacters = atob(res.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });

        fileSaver.saveAs(blob, "fly.xlsx");
      })
      .catch(err => {
        let error =
          err.response && err.response.data && err.response.data.error
            ? err.response.data.error
            : "Błąd! Nie udało się pobrać zleceń";

        this.props.enqueueSnackbar(error, { variant: "error" });
      });
  };

  render() {
    let { orders, pickerOpen, dateRange, filter } = this.state;
    const { classes } = this.props;
    let dateRangeOutput =
      dateRange.endDate === dateRange.startDate
        ? moment(dateRange.startDate).format("ddd, DD.MM")
        : moment(dateRange.startDate).format("DD.MM") +
        " - " +
        moment(dateRange.endDate).format("DD.MM");
    let filteredOrders = orders.length ? orders : [];

    // filtering of orders
    if (filter) {
      let regex = "";
      let filters = filter.toLowerCase().split(" ");

      filters.forEach(filter => {
        regex += "(?=.*" + filter + ")";
      });
      regex += ".*";

      filteredOrders = _.filter(orders, order => {
        let arr = [
          order.client.name,
          order.client.tel,
          order.costCenter,
          order.from.address,
          order.to.address,
          order.notes,
          order.orderer.login
        ];

        order.through.forEach(through => {
          arr.push(through.address);
        });

        let srex = new RegExp(regex, "gm");

        return srex.exec(arr.join(" ").toLowerCase()) !== null;
      });
    }

    if (this.state.holidays.length) {
      let fakeOrders = [];

      this.state.holidays.forEach(holiday => {
        let fakeOrder = {
          when: holiday.start,
          type: "holiday",
          end: holiday.end,
        };

        fakeOrders.push(fakeOrder);
      });

      filteredOrders = [...filteredOrders, ...fakeOrders];

      filteredOrders.sort(function compare(a, b) {
        var dateA = new Date(a.when);
        var dateB = new Date(b.when);
        return dateA - dateB;
      });
    }

    let isOneDay = dateRange.endDate === dateRange.startDate ? true : false;

    let heading = (
      <div className={classes.flex}>
        <Hidden smDown>
          <div>
            <Tooltip title="Pobierz" placement="right">
              <IconButton onClick={this.getCSV}>
                <Icon>assignment_returned</Icon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Odśwież listę" placement="right">
              <IconButton
                aria-label="Odśwież"
                color="primary"
                onClick={this.refresh}
              >
                <Icon>refresh</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </Hidden>

        {isOneDay && (
          <Icon
            onClick={() => this.changeDay(-1)}
            style={{ cursor: "pointer", fontSize: "36px" }}
          >
            keyboard_arrow_left
          </Icon>
        )}
        <div className={classes.date} onClick={this.togglePicker}>
          <Typography variant="h5" gutterBottom style={{ marginBottom: 0 }}>
            {dateRangeOutput}
          </Typography>
        </div>
        {isOneDay && (
          <Icon
            onClick={() => this.changeDay(1)}
            style={{ cursor: "pointer", fontSize: "36px" }}
          >
            keyboard_arrow_right
          </Icon>
        )}

        <Hidden smDown>
          <Input
            placeholder="Filtruj"
            value={this.state.filter}
            onChange={this.handleFilterChange}
          />
        </Hidden>
      </div>
    );

    let picker = null;

    if (pickerOpen) {
      picker = (
        <Dialog open={true} onClose={this.togglePicker}>
          <DialogContent>
            <DateRange
              locale={pl}
              ranges={[this.state.tempDateRange]}
              onChange={this.handleDateSelect}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.togglePicker}>Anuluj</Button>
            <Button onClick={this.confirmDateRanges} color="primary">
              Zastosuj
            </Button>
          </DialogActions>
        </Dialog>
      );
    }

    let previousDate =
      dateRange.endDate === dateRange.startDate ? dateRange.startDate : null;

    return (
      <div className="padding15vw">
        {heading}
        {picker}
        <div className={classes.root}>
          {filteredOrders.map(data => {
            let currentDate = moment(data.when).format("YYYY-MM-DD");
            let visibleDate = null;

            if (previousDate !== currentDate) {
              visibleDate = (
                <Typography variant="caption" gutterBottom>
                  {moment(currentDate).format("D MMMM")}
                </Typography>
              );
              previousDate = currentDate;
            }
            return (
              <React.Fragment key={data.id}>
                {visibleDate}
                {data.type === "holiday" ? (
                  <Pause data={data} key={"pauza" + data.when} />
                ) : (
                    <OrderRow
                      key={data.id}
                      data={data}
                      confirmOrder={() => this.confirmOrderHandler(data.id)}
                    />
                  )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    overflow: "visible"
  },
  addButton: {
    position: "fixed",
    top: "68px",
    right: "20px",
    zIndex: 99,
    [theme.breakpoints.down("sm")]: {
      top: "auto",
      bottom: "20px"
    }
  },
  roleInput: {
    marginTop: "20px",
    marginBottom: "20px"
  },
  relative: {
    position: "relative"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  dialog: {
    minWidth: "60vw"
  },
  overflow: {
    overflow: "visible"
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  flexGrow: {
    flexGrow: 1
  },
  date: {
    cursor: "pointer",
    display: "flex",
    padding: ".3em",
    borderRadius: "2px",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
});

export default withSnackbar(withStyles(styles)(Orders));
