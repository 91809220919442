import React from "react";
import ReactDOM from "react-dom";

import bugsnag from "bugsnag-js";
import createPlugin from "bugsnag-react";

import "typeface-roboto";
import "./index.css";
import axios from "axios";
import Main from "./Main";
import { PrivateRoute } from "./components/PrivateRoute";
import Login from "./components/login/Login";
import registerServiceWorker from "./registerServiceWorker";
import { Switch, Route, HashRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import blue from "@material-ui/core/colors/blue";
import pink from "@material-ui/core/colors/pink";

if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "https://fly.400.test:3443/api/";
} else {
  axios.defaults.baseURL = "/api/";
}

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers.common["Authorization"] = token;
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink
  },
  typography: {
    useNextVariants: true
  }
});

const bugsnagClient = bugsnag({
  apiKey: "5f5d3c1de0f16b4003c63ba6b7808a6b",
  beforeSend: report => {
    let user = { ...JSON.parse(localStorage.getItem("user")) };
    if (user && user.login) {
      report.user = { login: user.login, role: user.role };
    }
  }
});
const ErrorBoundary = bugsnagClient.use(createPlugin(React));

ReactDOM.render(
  <ErrorBoundary>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
    >
      <HashRouter>
        <MuiThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/logout"
              render={props => <Login {...props} logout={true} />}
            />
            <PrivateRoute path="/" component={Main} />
          </Switch>
        </MuiThemeProvider>
      </HashRouter>
    </SnackbarProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();
